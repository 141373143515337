import React from "react";
import cn from "classnames";
import { PinkCircle } from "@components/PinkCircle/PinkCircle";
import { Typography } from "..";
import * as styles from "./ImageAndBulletPoints.module.scss";
import { Variant } from "@components/Typography/Typography";
import { Colors } from "@helpers/Colors";
import classNames from "classnames";

type ImagePosition = "left" | "right";
type BulletsProps = {
  title: React.ReactNode;
  text: React.ReactNode;
};
type BulletStylesProps = {
  titleVariant: Variant;
  titleColor: Colors;
  textVariant: Variant;
  textColor: Colors;
  containerWidth?: number;
};
type ImageAndBulletPointsProps = {
  bullets: BulletsProps[];
  image: React.ReactNode;
  /** image postion (defualt=right) */
  imagePosition?: ImagePosition;
  bulletStyles: BulletStylesProps;
  /** Add negative margins around image with shadows */
  withImageShadowMargins?: boolean;
  width?: number;
  /** Distance between the image and the bullets */
  gap?: number;
};

export default function ImageAndBulletPoints({
  bullets,
  image,
  imagePosition = "right",
  bulletStyles,
  withImageShadowMargins = true,
  width = 1180,
  gap,
}: ImageAndBulletPointsProps) {
  return (
    <div
      className={cn(styles.imageAndBulletPoints, {
        [styles.imageAndBulletPoints__reverse]: imagePosition === "left",
      })}
      style={{ maxWidth: width, gap }}
    >
      <div
        className={styles.bullets}
        style={{
          maxWidth: bulletStyles.containerWidth,
        }}
      >
        {bullets.map((bullet, i) => (
          <BulletSection key={i} {...bullet} bulletStyles={bulletStyles} />
        ))}
      </div>
      <div
        className={classNames(styles.image, {
          [styles.imageMargin]: withImageShadowMargins,
        })}
      >
        {image}
      </div>
    </div>
  );
}

type BulletSectionProps = BulletsProps & {
  bulletStyles: BulletStylesProps;
};
export const BulletSection = ({
  title,
  text,
  bulletStyles,
}: BulletSectionProps) => (
  <div className={styles.bulletWrapper}>
    <PinkCircle />
    <Typography
      className={styles.bulletTitle}
      variant={bulletStyles.titleVariant}
      color={bulletStyles.titleColor}
    >
      {title}
    </Typography>
    <Typography
      variant={bulletStyles.textVariant}
      color={bulletStyles.textColor}
    >
      {text}
    </Typography>
  </div>
);
