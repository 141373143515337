// extracted by mini-css-extract-plugin
export var buttonContainer = "GetADemo-module--buttonContainer--qT6Fl";
export var centered = "GetADemo-module--centered--uTyGN";
export var demoButton = "GetADemo-module--demoButton--xFZRb";
export var emailAndButton = "GetADemo-module--emailAndButton--6vmzn";
export var emailInput = "GetADemo-module--emailInput--F-6hI";
export var getADemo = "GetADemo-module--getADemo--iEOv4";
export var image = "GetADemo-module--image--ZuL6Q";
export var left = "GetADemo-module--left--pW15t";
export var leftSection = "GetADemo-module--leftSection--00UMl";
export var limitWidthOnLargeScreens = "GetADemo-module--limitWidthOnLargeScreens--ZtKde";
export var signup = "GetADemo-module--signup--Q5ZeI";
export var subtitle = "GetADemo-module--subtitle--r0bnc";
export var subtitleBioTech = "GetADemo-module--subtitleBioTech--uKzX6";
export var title = "GetADemo-module--title--hPFrI";
export var titleBioTech = "GetADemo-module--titleBioTech--GmGC9";