import React from "react";
import * as styles from "./GetADemo.module.scss";
import cn from "classnames";
import {Typography, TypographyV2} from "..";
import {
	CreateFreeAccountButton,
	DemoButtonWithInput,
} from "@components/Button_new/Button";
import { Colors } from "@helpers/Colors";
import { useWindowSize } from "@helpers/Hooks";

type GetADemoProps = {
	titleText: string;
	subtitleText: string;
	textColor?: Colors;
	imageNode: React.ReactNode;
	linkColor?: "marine" | "medium_pink" | "ui_01";
	inputLightMode?: boolean;
	titleWidth?: number;
	subtitleWidth?: number;
	maxWidth?: number;
	showSignUp?: boolean;
};
export const GetADemo = ({
	titleText,
	subtitleText,
	textColor = "ui-01",
	imageNode,
	linkColor = "marine",
	inputLightMode,
	titleWidth = 567,
	subtitleWidth = 567,
	maxWidth = 1134,
	showSignUp = false,
}: GetADemoProps) => {
	let { isDesktop } = useWindowSize();

	const isBioTech = titleText === "Optimize industrial biotech research and development";

	const titleStyles = isBioTech ? styles.titleBioTech : styles.title;

	const subtitleStyles = isBioTech ? styles.subtitleBioTech : styles.subtitle;

	const leftOrCenteredStyles = isBioTech ? styles.leftSectionBioTech : styles.centered;

	return (
		<div
			className={styles.getADemo}
			style={{ maxWidth: maxWidth }}
		>
			<div
				className={cn(styles.leftSection, { [styles.signup]: showSignUp })}
				style={{ 
					width: isDesktop ? 567 : undefined, 
					minWidth: isDesktop ? 567 : undefined 
				}}
			>
				<TypographyV2
					variant="HEADING_1"
					color={textColor}
					className={titleStyles}
					style={{ maxWidth: titleWidth }}
				>
					{titleText}
				</TypographyV2>
				{showSignUp ? (
					<TypographyV2
						variant="BODY_TEXT_EXTRA_LARGE"
						mobileVariant="BODY_TEXT_LARGE"
						weight="REGULAR"
						color="brand-subtle"
						className={subtitleStyles}
						style={{ maxWidth: subtitleWidth }}
					>
						{subtitleText}
					</TypographyV2>
				) : (
					<Typography
						variant="SUBTITLE"
						color={textColor}
						className={styles.subtitle}
						style={{ maxWidth: subtitleWidth }}
					>
						{subtitleText}
					</Typography>
				)}
				<div className={cn(styles.buttonContainer, {[leftOrCenteredStyles]: showSignUp})}>
					<div style={{ maxWidth: 500, marginBottom: 24 }}>
						<DemoButtonWithInput lightmode={inputLightMode} showSignUp={showSignUp} />
					</div>
					{!showSignUp && (
						<CreateFreeAccountButton color={linkColor} />
					)}
				</div>
			</div>
			<div className={styles.image}>
				{imageNode}
			</div>
		</div>
	);
};
